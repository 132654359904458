import React, {Component} from 'react';
import * as moment from "moment/moment.js";
import './Counter.css';

export default class Counter extends Component {

    state = {
        release: moment("2020-07-31 23:59:59", "YYYY-MM-DD HH:mm:ss"),
        current: moment()
    }

    componentDidMount = () =>{
        setInterval(() => {
            this.setState({ current: moment() });
        }, 1000)
    }

    render() {
        return (
            <div className="counter">
                <span className="display">
                    <span>{ moment.duration(this.state.release.diff(this.state.current)).days() }</span>
                    <span>Days</span>
                </span>
                <span className="separator">:</span>
                <span className="display">
                    <span>{ moment.duration(this.state.release.diff(this.state.current)).hours() }</span>
                    <span>Hours</span>
                </span>
                <span className="separator">:</span>
                <span className="display">
                    <span>{ moment.duration(this.state.release.diff(this.state.current)).minutes() }</span>
                    <span>Minutes</span>
                </span>
                <span className="separator">:</span>
                <span className="display">
                    <span>{ moment.duration(this.state.release.diff(this.state.current)).seconds() }</span>
                    <span>Seconds</span>
                </span>
            </div>
        );
    }
}