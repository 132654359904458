import React from 'react';
import logo from './logo.svg';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import Counter from './components/Counter'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="comming-soon">
          Comming soon...
        </p>
        <Counter />
        <div className="icons">
          <a
            className="App-link"
            href="https://twitter.com/_dancortes"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            className="App-link"
            href="https://github.com/dancortes-git"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-github"></i>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
